import React from 'react';
import { Box, Flex } from '../../../Layout';
import {Button} from 'devextreme-react';
import { SelectBox } from 'devextreme-react/select-box';
import { getFirebaseIdToken } from "../../../../utils/auth";

import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';
import VesselsInPortVsAnchorageChart from './VesselsInPortVsAnchorageChart';
import PortCongestionChartByPort from './PortCongestionChartByPort';
import CongestionByRegionByPortChart from './CongestionByRegionByPortChart';


class PortCongestionDetail extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        portGroup: props.defaultPortGroup,
        portRegion: null
      };

      this.DDown_CongestionPortGroupSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/DDown_CongestionPortGroup`,
          ...oDataStoreDefaultSettingWithToken(this.props.firebase)
        })
        ,sort: [{selector:'portGroup'}]
      });

      this.DDown_CongestionPortRegionSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/DDown_CongestionPortRegion`,
          ...oDataStoreDefaultSettingWithToken(this.props.firebase)
        })
        ,sort: [{selector:'portRegion'}]
      });      

      this.portGroupChanged = ({ component }) => {
        if (component.option('selectedItem')) {
          this.setState({ portGroup: component.option('selectedItem').portGroup, portRegion: null });
          this.vesselsInPortVsAnchorageChart.load(null, component.option('selectedItem').portGroup);
        }
      };

      this.portRegionChanged = ({ component }) => {
        if (component.option('selectedItem')) {
          this.setState({ portRegion: component.option('selectedItem').portRegion, portGroup: null });
          this.congestionByRegionByPortChart.load(component.option('selectedItem').portRegion, null);
        }
      };
      this.setVesselsInPortVsAnchorageChart = (ref) => {
        if (ref != null)
            this.vesselsInPortVsAnchorageChart = ref;
      };
      this.setCongestionByRegionByPortChart = (ref) => {
        if (ref != null)
            this.congestionByRegionByPortChart = ref;
      };
      this.hideDetail = this.hideDetail.bind(this);
    }

    hideDetail() {
      this.props.showHideDetailCallback(false);
    }
    componentDidMount() {
      window.scrollTo(0, 0)
    }

    render() {
      return (
      <Flex wrap={['wrap', 'wrap','nowrap']}>
      <Box width={[1, 1, 1]} m={[0,0,'2rem']}>
          <Flex wrap={['nowrap']} style={{verticalAlign: 'bottom'}} >
            <Button style={{marginRight: 10}} icon="back" hint="Back" onClick={this.hideDetail} height='30'/>
            <h3 style={{marginTop: 5}}>{`Port Congestion Watch`}</h3>

          </Flex>  
          <Flex wrap={['wrap', 'wrap','nowrap']}>
            <div style={{marginRight: 40}}>
            <div>Region:</div>
            <SelectBox dataSource={this.DDown_CongestionPortRegionSource}
                  displayExpr="portRegion"
                  valueExpr="portRegion"
                  value={this.state.portRegion}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'portRegion'}
                  minSearchLength={3}                  
                  searchTimeout={200}
                  showDataBeforeSearch={true}
                  onValueChanged={this.portRegionChanged} />
            </div>
            <div>
            <div>Port:</div>
            <SelectBox dataSource={this.DDown_CongestionPortGroupSource}
                  displayExpr="portGroup"
                  valueExpr="portGroup"
                  value={this.state.portGroup}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'portGroup'}
                  minSearchLength={3}                  
                  searchTimeout={200}
                  showDataBeforeSearch={true}
                  onValueChanged={this.portGroupChanged} />
            </div>


          </Flex>
          {
            this.state.portGroup &&
            <VesselsInPortVsAnchorageChart ref={this.setVesselsInPortVsAnchorageChart} firebase={this.props.firebase} defaultPortGroup={this.state.portGroup} />
          }
          {
            this.state.portRegion &&
            <CongestionByRegionByPortChart ref={this.setCongestionByRegionByPortChart} firebase={this.props.firebase} defaultPortRegion={this.state.portRegion} />
          }
      </Box>
      </Flex>
      );  
    }
  }

  export default PortCongestionDetail;