import React from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Box } from '../../../Layout';
import PortCongestionChart from './PortCongestionChart';
import { getFirebaseIdToken } from "../../../../utils/auth";

import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';



class PortCongestionGrid extends React.Component {
    constructor(props) {
      super(props);
      this.state = {selectedDate: props.defaultDate};
      this.CongestionBubbleMapByPortGroupSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/CongestionBubbleMapByPortGroup`,
          onLoaded: function (result) {
            result.forEach(element => {
              element.chartData = [{portGroup: element.portGroup, aTeu:element.aTeu, pTeu:element.pTeu}];
            }        
            );
    
          },
          ...oDataStoreDefaultSettingWithToken(this.props.firebase)
        })
      });      
      this.CongestionBubbleMapByPortGroupSource.filter([[['date','>=',props.defaultDate],'and',['date','<',this.addDays(props.defaultDate,1)]]]);
      this.setGrid = (ref) => {
        if (ref != null) {
            this.resultGrid = ref.instance;
            this.resultGrid.ParentElt = this;
        }
      };
      this.showDetail = this.showDetail.bind(this);
    }
    addDays(baseDate, days) {
      var date = new Date(baseDate.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    load(filter, selectedDate) {
      this.setState({selectedDate: selectedDate});
      var thisObj = this;
      thisObj.CongestionBubbleMapByPortGroupSource.filter(filter);
      thisObj.resultGrid.refresh();      
    }
    showDetail(portGroup) {
      this.props.showHideDetailCallback(true, portGroup);
    }
    portGroupTemplate(props) {  
      const link = <a style={{color:'blue'}} href={`javascript: void(0)`} onClick={(e) => {props.component.ParentElt.showDetail(props.data.portGroup)}}>{props.data.portGroup}</a>  
      return link  
    }   
    render() {
      return (
        <React.Fragment>
        <Box width={'100%'}>
          <h3>{`Global Containership Port Congestion as of ${this.state.selectedDate.toLocaleDateString('em-US',{ year: 'numeric', month: 'short', day: 'numeric' })} (All Hotspots)`}</h3>
          <div>*click the port name to show the corresponding Port Congestion Watch</div>
          <DataGrid
            elementAttr={{
              class: "myClass"
            }}
            dataSource={this.CongestionBubbleMapByPortGroupSource}
            showBorders={true}
            ref={this.setGrid}
            allowColumnResizing={true}
            columnAutoWidth={false}
            wordWrapEnabled={false}
            rowAlternationEnabled={false}
            >

            <Column dataField='portGroup'
              caption="Port Name"
              dataType={'string'}
              minWidth={120}
              cellRender={this.portGroupTemplate}
            />

            <Column dataField='aShips'
              caption='Ships at Anchorage'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              width={"100"}
            />
            <Column dataField='pShips'
              caption='Ships at Port'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              width={"70"}
            />
            <Column dataField='aTeu'
              caption='TEU at Anchorage'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              width={"100"}
              sortIndex={0}
              sortOrder={"desc"}
            />
            <Column dataField='pTeu'
              caption='TEU at Port'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              width={"70"}
            />
            <Column dataField='qBerthRatio'
              dataType={'number'}
              format={{type:'fixedPoint',precision:2}}
              caption='Queue to Berth Ratio'
              width={"100"}
            />
            <Column
              minWidth={350}
              cellRender={PortCongestionChart}
              caption='TEU at Anchorage/Port'
            />   

          </DataGrid>
        </Box>

        </React.Fragment>
      );
    }
  }

  export default PortCongestionGrid;