import React from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import { Box } from '../../../Layout';
import { CongestionOverviewDataSource } from '../commonDataSource';
import CongestionOverviewChart from './CongestionOverviewChart';


class CongestionOverviewGrid extends React.Component {
    constructor(props) {
      super(props);
      CongestionOverviewDataSource.filter([[['date','>=',props.defaultDate],'and',['date','<',this.addDays(props.defaultDate,1)]]]);
      this.setGrid = (ref) => {
        if (ref != null)
            this.resultGrid = ref.instance;
      };
      this.load = this.load.bind(this);
      this.state = {selectedDate: props.defaultDate};
    }
    addDays(baseDate, days) {
      var date = new Date(baseDate.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    load(filter, selectedDate) {
      this.setState({selectedDate: selectedDate});
      var thisObj = this;
      /*
      getFirebaseIdToken(this.props.firebase, () => {
        CongestionOverviewDataSource.filter(filter);
        thisObj.resultGrid.refresh();      
      });*/
      CongestionOverviewDataSource.filter(filter);
      thisObj.resultGrid.refresh();      
    }

    render() {
      return (
        <React.Fragment>
        <Box width={'100%'}>
          <h3>{`Global Containership Fleet Snapshot as of ${this.state.selectedDate.toLocaleDateString('em-US',{ year: 'numeric', month: 'short', day: 'numeric' })}`}</h3>

          <DataGrid
            elementAttr={{
              class: "myClass"
            }}
            dataSource={CongestionOverviewDataSource}
            showBorders={true}
            ref={this.setGrid}
            allowColumnResizing={true}
            columnAutoWidth={false}
            wordWrapEnabled={false}
            rowAlternationEnabled={false}
            >

            <Column dataField='statusCat'
              caption="Ship Status"
              dataType={'string'}
              width={"35%"}
              minWidth={50}
              allowSorting={false}
            />

            <Column dataField='ttlShips'
              caption='Ships'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              width={"60"}
              allowSorting={false}
            />

            <Column dataField='ttlTeu'
              dataType={'number'}
              format={{type:'fixedPoint',precision:0}}
              caption='TEU'
              width={"80"}
              allowSorting={false}
            />
            <Column
              minWidth={350}
              width={"35%"}
              cellRender={CongestionOverviewChart}
              caption='Total TEU'
            />            

          </DataGrid>
        </Box>

        </React.Fragment>
      );
    }
  }

  export default CongestionOverviewGrid;