import React from 'react';
import Chart, {AdaptiveLayout,SeriesTemplate, CommonSeriesSettings, Size, Series, ValueAxis, ArgumentAxis, Tooltip,  Legend,  Label, Marker} from 'devextreme-react/chart';

function customizeTooltip(pointInfo){
    
    if(pointInfo){
        return{
            text: `${pointInfo.argumentText}: ${formatNumber(pointInfo.valueText)}` 
        };
    } else {
        return{
            text: ``
        };
    }   
}

const formatNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits:0
}).format;

export default function CongestionOverviewChart(gridData){
    var data = gridData.data.chartData;
    var custWidth = Math.round(gridData.data.chartData[0].teu / 40000);
/*    if(data){
        data = data.map((rec) => {
            rec.dummy = '';
            return rec;
        });
    } */
    return(
        <div className="chart-cell" >
            <Chart
                dataSource={data}  rotated={true} 
                >
                <CommonSeriesSettings type="bar" argumentField="cat"/>
                <Series
                    
                    valueField="teu"
                    name="TEU"
                    color="#BE1E2D"
                />
                
                <ArgumentAxis visible={false}>
                    <Label visible={false} />

                </ArgumentAxis>
                <ValueAxis visible={false} grid={false} tick={false} endOnTick={false} placeholderSize={0}>
                    <Label visible={false} />
                    
                    
                </ValueAxis>
                <Legend visible={false} />
                
                <Size height={15} width={custWidth}/>
                
                <Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip} />
            </Chart>
        </div>
    )
}


