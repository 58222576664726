import React from 'react';
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Tooltip,
  SeriesTemplate,
} from 'devextreme-react/chart';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';
import { getFirebaseIdToken } from "../../../../utils/auth";
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';



class PortCongestionChartByPort extends React.Component {
  constructor(props) {
    super(props);
    this.CongestionGraphByPortRegionPortGroupDataSource = new DataSource({
      store: new ODataStore({
        url: `${process.env.GATSBY_ODATA_SERVER_URL}/CongestionGraphByPortRegionPortGroup`,
        ...oDataStoreDefaultSettingWithToken(this.props.firebase)
      }),
      paginate:false
    });    
    if (props.defaultPortGroup) {
      this.CongestionGraphByPortRegionPortGroupDataSource.filter([[['portGroup','=',props.defaultPortGroup]]]);
      this.state = {dataName: props.defaultPortGroup}
    }
    if (props.defaultPortRegion) {
      this.CongestionGraphByPortRegionPortGroupDataSource.filter([[['portRegion','=',props.defaultPortRegion]]]);
      this.state = {dataName: props.defaultPortRegion}
    }
    //this.CongestionGraphByPortRegionPortGroupDataSource.paginate(false);
    this.load = this.load.bind(this);
    this.chartDone = this.chartDone.bind(this);
  }

  load(portRegion, portGroup) {
    if (portGroup) {
      this.CongestionGraphByPortRegionPortGroupDataSource.filter([[['portGroup','=',portGroup]]]);
      this.setState({dataName: portGroup});
    }
    if (portRegion) {
      this.CongestionGraphByPortRegionPortGroupDataSource.filter([[['portRegion','=',portRegion]]]);
      this.setState({dataName: portRegion});
    }
    this.CongestionGraphByPortRegionPortGroupDataSource.reload();
  }

  chartDone(e) {
var a = 10000;
  }

  render() {
    return (
      <div id="chart">
        <Chart
          palette="Carmine"
          paletteExtensionMode="extrapolate"
          title="Port Congestion"
          dataSource={this.CongestionGraphByPortRegionPortGroupDataSource}
          onDone={this.chartDone}
        >
          <CommonSeriesSettings
            argumentField="valueDate"
            type="area"
          />
          <Series valueField="aTeu" name={`${this.state.dataName}`} color="#678F32"></Series>
          <Margin bottom={20} />
          <ArgumentAxis argumentType='datetime' tickInterval={"month"}/>
          <ValueAxis 
          title={"Capacity at Anchorage (TEU)"} />
          <Legend
            
            verticalAlignment="top"
            horizontalAlignment="left"
          />
          <Tooltip enabled={true} />
        </Chart>
      </div>
    );
  }
}

export default PortCongestionChartByPort;
