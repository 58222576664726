import React from 'react';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  Tooltip,
  Legend,
  Margin,
  SeriesTemplate,
  Label,
  Crosshair 
} from 'devextreme-react/chart';
import RangeSelector, {
  Size as RSSize,
  Chart as RSChart,
  Scale as RSScale,
  Behavior as RSBehavior,
} from 'devextreme-react/range-selector';

import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';


class CongestionByRegionChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {visualRange: null};
    var thisObj = this;
    this.CongestionGraphByPortRegionDataSource = new DataSource({
      store: new ODataStore({
        url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/CongestionGraphByPortRegion`,
        onLoaded: function (result) {
          if (!thisObj.state.visualRange)
            thisObj.setState({visualRange: [new Date(Math.min(...result.map(o => new Date(o.valueDate)))), new Date(Math.max(...result.map(o => new Date(o.valueDate))))]});
        },
        ...oDataStoreDefaultSettingWithToken(this.props.firebase)
      }),
      paginate:false
    });
    
    this.updateVisualRange = this.updateVisualRange.bind(this);
    this.onLegendClick = this.onLegendClick.bind(this);
    this.showRanger = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) < 0;
  }
  updateVisualRange(e) {
    this.setState({ visualRange: e.value });
  }
  onLegendClick({ target: series }) {
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }
  render() {
    return (
      <div id="chart-demo">
        {(!this.showRanger ||this.state.visualRange) && <><Chart
          palette="Carmine"
          //palette={["#2079b5","#fe7f10","#2ba02f","#d42828","#2079b5","#fe7f10","#2ba02f","#d42828","#2079b5","#fe7f10","#2ba02f","#d42828","#2079b5","#fe7f10","#2ba02f","#d42828"]}
          paletteExtensionMode="extrapolate"
          title="Port Congestion by Region"
          dataSource={this.CongestionGraphByPortRegionDataSource}
          onLegendClick={this.onLegendClick}
        >
          <CommonSeriesSettings
            argumentField="date"
            valueField="aTeu"
            type="stackedarea"
          />
          <SeriesTemplate nameField="portRegion"/>
          <Margin bottom={0} />
          <ArgumentAxis argumentType='datetime' tickInterval={"month"} visualRange={this.showRanger?this.state.visualRange:null}/>
          <ValueAxis title={"Capacity in TEU"} />
          <Legend
            
            verticalAlignment="top"
            horizontalAlignment="left"
          />
          <Crosshair
            enabled={true}>
            <Label visible={true} />
          </Crosshair>
          <Tooltip enabled={true} />
        </Chart>
        </>}
        {this.showRanger && <RangeSelector
          dataSource={this.CongestionGraphByPortRegionDataSource}
          onValueChanged={this.updateVisualRange}
        >
          <RSSize height={150} />
          <Margin left={150} />
          <RSScale tickInterval={'month'} minorTickCount={1} minRange={'month'} valueType={'datetime'}/>
          <RSChart           palette="Carmine"
          //palette={["#2079b5","#fe7f10","#2ba02f","#d42828","#2079b5","#fe7f10","#2ba02f","#d42828","#2079b5","#fe7f10","#2ba02f","#d42828","#2079b5","#fe7f10","#2ba02f","#d42828"]}
          paletteExtensionMode="extrapolate">
            <RSBehavior callValueChanged="onMoving" />
            <Legend visible={false} />
            <CommonSeriesSettings
            argumentField="date"
            valueField="aTeu"
            type="stackedarea"
            />
            <SeriesTemplate nameField="portRegion"/>
          </RSChart>
        </RangeSelector>}
      

      </div>
    );
  }
}

export default CongestionByRegionChart;
