import React from 'react';
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  Export,
  Crosshair,
  Label,
  Legend,
  Margin,
  Tooltip,
  SeriesTemplate,
  AggregationInterval,
} from 'devextreme-react/chart';
import RangeSelector, {
  Size as RSSize,
  Chart as RSChart,
  Scale as RSScale,
  Behavior as RSBehavior,
} from 'devextreme-react/range-selector';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';
import { getFirebaseIdToken } from "../../../../utils/auth";
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';



class CongestionByRegionByPortChart extends React.Component {
  constructor(props) {
    super(props);
    var thisObj = this;
    this.state = {visualRange: null};
    this.CongestionGraphByDispGrpDataSource = new DataSource({
      store: new ODataStore({
        url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/CongestionGraphByDispGrp`,
        onLoaded: function (result) {
          if (!thisObj.state.visualRange)
            thisObj.setState({visualRange: [new Date(Math.min(...result.map(o => new Date(o.valueDate)))), new Date(Math.max(...result.map(o => new Date(o.valueDate))))]});
        },
        ...oDataStoreDefaultSettingWithToken(this.props.firebase)
      }),
      paginate:false
    });    
    if (props.defaultPortRegion) {
      this.CongestionGraphByDispGrpDataSource.filter([[['portRegion','=',props.defaultPortRegion]]]);
      this.state = {dataName: props.defaultPortRegion}
    }
    //this.CongestionGraphByPortRegionPortGroupDataSource.paginate(false);
    this.load = this.load.bind(this);
    this.chartDone = this.chartDone.bind(this);
    
    this.updateVisualRange = this.updateVisualRange.bind(this);
    this.onLegendClick = this.onLegendClick.bind(this);    
  }
  updateVisualRange(e) {
    this.setState({ visualRange: e.value });
  }
  onLegendClick({ target: series }) {
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }
  load(portRegion, portGroup) {
    if (portRegion) {
      this.CongestionGraphByDispGrpDataSource.filter([[['portRegion','=',portRegion]]]);
      this.setState({dataName: portRegion});
    }
    this.CongestionGraphByDispGrpDataSource.reload();
  }

  chartDone(e) {
var a = 10000;
  }

  render() {
    return (
      <div id="chart">
        {this.state.visualRange && <><Chart
          palette="Material"
          paletteExtensionMode="extrapolate"
          title={`Port Congestion - ${this.state.dataName}`}
          dataSource={this.CongestionGraphByDispGrpDataSource}
          onLegendClick={this.onLegendClick}
          onDone={this.chartDone}
        >
          <CommonSeriesSettings
            argumentField="valueDate"
            valueField="aTeu"
            type="stackedarea"
          />
          <SeriesTemplate nameField="displayGroup"/>
          <Margin bottom={20} />
          <ArgumentAxis 
            argumentType='datetime' 
            tickInterval={"month"}
            visualRange={this.state.visualRange}/>
          <AggregationInterval days={30}/>
          <ValueAxis 
          title={"Capacity at Anchorage (TEU)"} />
          <Legend
            
            verticalAlignment="top"
            horizontalAlignment="center"
          />
          <Tooltip enabled={true} />
          <Crosshair
            enabled={true}>
            <Label visible={true} />
          </Crosshair>          
        </Chart></>}
        <RangeSelector
          dataSource={this.CongestionGraphByDispGrpDataSource}
          onValueChanged={this.updateVisualRange}
        >
          <RSSize height={150} />
          <Margin left={60} />
          <RSScale tickInterval={'month'} minorTickCount={1} minRange={'month'} valueType={'datetime'}/>
          <RSChart           palette="Material"
          paletteExtensionMode="extrapolate">
            <RSBehavior callValueChanged="onMoving" />
            <Legend visible={false} />
            <CommonSeriesSettings
            argumentField="valueDate"
            valueField="aTeu"
            type="stackedarea"
            />
            <SeriesTemplate nameField="displayGroup"/>
          </RSChart>
        </RangeSelector>
      </div>
    );
  }
}

export default CongestionByRegionByPortChart;
