import React from 'react';
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  Pane,
  Export,
  Legend,
  Margin,
  Tooltip,
  Crosshair,
  Label,
  SeriesTemplate,
} from 'devextreme-react/chart';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';
import { getFirebaseIdToken } from "../../../../utils/auth";
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';
import RangeSelector, {
  Size as RSSize,
  Chart as RSChart,
  Scale as RSScale,
  Behavior as RSBehavior,
} from 'devextreme-react/range-selector';


class VesselsInPortVsAnchorageChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {visualRange: null};
    var thisObj = this;
    this.CongestionGraphByPortRegionPortGroupDataSource = new DataSource({
      store: new ODataStore({
        url: `${process.env.GATSBY_ODATA_SERVER_URL}/CongestionGraphByPortRegionPortGroup`,
        onLoaded: function (result) {
          if (!thisObj.state.visualRange)
            thisObj.setState({visualRange: [new Date(Math.min(...result.map(o => new Date(o.valueDate)))), new Date(Math.max(...result.map(o => new Date(o.valueDate))))]});
        },
        ...oDataStoreDefaultSettingWithToken(this.props.firebase)
      }),
      paginate:false
    });    
    if (props.defaultPortGroup) {
      this.CongestionGraphByPortRegionPortGroupDataSource.filter([[['portGroup','=',props.defaultPortGroup]]]);
      this.state = {dataName: props.defaultPortGroup}
    }
    if (props.defaultPortRegion) {
      this.CongestionGraphByPortRegionPortGroupDataSource.filter([[['portRegion','=',props.defaultPortRegion]]]);
      this.state = {dataName: props.defaultPortRegion}
    }
    this.setChart = (ref) => { 
      if (ref != null) {
        this._chart = ref.instance;
        this._chart.render();
      }
    }; 
    
    this.updateVisualRange = this.updateVisualRange.bind(this);
    this.onLegendClick = this.onLegendClick.bind(this);        
    //this.CongestionGraphByPortRegionPortGroupDataSource.paginate(false);
  }
  componentDidMount() {
    //this._chart.render();
  }
  updateVisualRange(e) {
    this.setState({ visualRange: e.value });
  }
  onLegendClick({ target: series }) {
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }  
  load(portRegion, portGroup) {
    if (portGroup) {
      this.CongestionGraphByPortRegionPortGroupDataSource.filter([[['portGroup','=',portGroup]]]);
      this.setState({dataName: portGroup});
    }
    if (portRegion) {
      this.CongestionGraphByPortRegionPortGroupDataSource.filter([[['portRegion','=',portRegion]]]);
      this.setState({dataName: portRegion});
    }
    this.CongestionGraphByPortRegionPortGroupDataSource.reload();
  }

  render() {
    return (
      <div id="chart-demo">
        {this.state.visualRange && <><Chart
          ref={this.setChart}
          palette="Carmine"
          paletteExtensionMode="extrapolate"
          title="Number of Vessels in Port vs Anchorage (Top) / TEU at Anchorage (Bottom)"
          dataSource={this.CongestionGraphByPortRegionPortGroupDataSource}
          height={700}

        >
          <CommonSeriesSettings
            argumentField="valueDate"
            
          />
          <Series pane="topPane" type="stackedarea" valueField="pShips" name={`# of Vessels in ${this.state.dataName} port(s)`} color="#678F32"></Series>
          <Series pane="topPane" type="stackedarea" valueField="aShips" name={`# of Vessels at ${this.state.dataName} Anchorage`} color="#00B0F0"></Series>
          <Series pane="topPane"
          axis="ratio"
          type="line"
          valueField="qBerthRatio"
          name="Queue to Berth Ratio"
          color="#BE1E2D"
          point={{visible: false}}
          
          />   
          <Series pane="bottomPane" type="area" valueField="aTeu" name={`TEU at ${this.state.dataName} Anchorage`} ></Series>
          <Pane name="topPane"  height="250" />
          <Pane name="bottomPane"  height="250" />
          
          <ArgumentAxis argumentType='datetime' tickInterval={"month"} visualRange={this.state.visualRange}/>
          <ValueAxis pane="topPane"
          title={"Total Number of Vessels"} />
          <ValueAxis pane="topPane"
          name="ratio"
          position="right"
          title={"Queue to Berth Ratio"}/>
          <ValueAxis pane="bottomPane"
          title={"Capacity at Anchorage in TEU"} />
          <Legend            
            verticalAlignment="top"
            horizontalAlignment="center"
          />
          <Tooltip enabled={true} />
          <Crosshair
            enabled={true}>
            <Label visible={true} />
          </Crosshair> 
          <Margin bottom={1} />
        </Chart></>}
        <RangeSelector
          dataSource={this.CongestionGraphByPortRegionPortGroupDataSource}
          onValueChanged={this.updateVisualRange}
          width={'90%'}
        >
          <RSSize height={150} />
          <Margin left={50} />
          <RSScale tickInterval={'month'} minorTickCount={1} minRange={'month'} valueType={'datetime'}/>
          <RSChart  palette="Carmine"
          paletteExtensionMode="extrapolate">
            <RSBehavior callValueChanged="onMoving" />
            <Legend visible={false} />
            <CommonSeriesSettings
            argumentField="valueDate"
            />
            <Series pane="topPane" type="stackedarea" valueField="pShips" name={`# of Vessels in ${this.state.dataName} port(s)`} color="#678F32"></Series>
            <Series pane="topPane" type="stackedarea" valueField="aShips" name={`# of Vessels at ${this.state.dataName} Anchorage`} color="#00B0F0"></Series>
          </RSChart>
        </RangeSelector>
      </div>
    );
  }
}

export default VesselsInPortVsAnchorageChart;
