import React from 'react';
import VectorMap, {
  Label,
  Layer,
  Legend,
  Source,
  Tooltip,
  ControlBar
} from 'devextreme-react/vector-map';
import * as mapsData from 'devextreme/dist/js/vectormap-data/world.js';
//import {CongestionBubbleMapByPortGroupSource} from '../commonDataSource';
import { Box, Flex } from '../../../Layout';
import { getFirebaseIdToken } from "../../../../utils/auth";
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';

const bounds = [-180, 77, 180, -60];
const colorGroups = [0, 50000, 100000, 200000, 400000, 1000000];
class PortCongestionMap extends React.Component {
    constructor(props) {
      super(props);
      this.topSize = 30;
      var thisObj = this;

      this.CongestionBubbleMapByPortGroupSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/CongestionBubbleMapByPortGroup`,
          onLoaded: function (result) {
            result.forEach(element => {
              element.coordinates = [element.longitudeValue, element.latitudeValue];
              element.attributes = {text: element.portGroup,
                value: element.aTeu,
                tooltip: `<div style='font-size:20px; margin-bottom: 25px'><b>${element.portGroup}</b></div>\n \n<div style='font-size:15px'><b>TEU at Anchorage:</b> ${element.aTeu.toLocaleString(undefined)}\n<b>TEU at Port:</b> ${element.pTeu?element.pTeu.toLocaleString(undefined):'-'}\n<b>Ships at Anchorage:</b> ${element.aShips}\n<b>Ships at Port:</b> ${element.pShips}\n<b>Queue to Berth Ratio:</b> ${element.qBerthRatio?element.qBerthRatio.toFixed(2):'-'}</div>`
              }
            });
            thisObj.map.option("layers[1].dataSource", result);
          },
          ...oDataStoreDefaultSetting
        })
        ,sort: [{selector:'aTeu', desc:true}], pageSize: thisObj.topSize
      });       
      this.CongestionBubbleMapByPortGroupSource.filter([[['date','>=',props.defaultDate],'and',['date','<',this.addDays(props.defaultDate,1)]]]);
      this.setMap = (ref) => { 
        if (ref != null)
          this.map = ref.instance;
      };    
      this.state = {selectedDate: props.defaultDate};
      this.load = this.load.bind(this);
      this.handleMarkerClick = this.handleMarkerClick.bind(this);
    }
    addDays(baseDate, days) {
      var date = new Date(baseDate.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    load(filter, selectedDate) {
      this.setState({selectedDate: selectedDate});
      var thisObj = this;
      thisObj.CongestionBubbleMapByPortGroupSource.filter(filter);
      thisObj.CongestionBubbleMapByPortGroupSource.load();
    }
    customizeTooltip(arg) {
      if (arg.layer.type === 'marker') {
        return { text: arg.attribute('tooltip') };
      }
      return null;
    }
    
    customizeText(arg) {
      return ['< 8000K', '8000K to 10000K', '> 10000K'][arg.index];
    }
    
    customizeItems(items) {
      return items.reverse();
    }
    handleMarkerClick(e) {
      if (e.target.layer.type === 'marker') {
        this.props.showHideDetailCallback(true, e.target.attribute('text'));
      }
    }

    render() {
      return (
        <>
      <h3>{`Global Containership Port Congestion as of ${this.state.selectedDate.toLocaleDateString('em-US',{ year: 'numeric', month: 'short', day: 'numeric' })} (Top ${this.topSize} Hotspots)`}</h3>
      <div>*click the bubble to show the Port Congestion Watch</div>
      <VectorMap ref={this.setMap} style={{height:"100%"}} onClick={this.handleMarkerClick}
        id="vector-map" bounds={bounds} zoomingEnabled={true} panningEnabled={true}
        >
        <ControlBar enabled={false}></ControlBar>
        <Layer
          dataSource={mapsData.world}
          hoverEnabled={false}>
        </Layer>
        <Layer
          dataSource={this.CongestionBubbleMapByPortGroupSource}
          name="bubbles"
          elementType="bubble"
          dataField="value"
          minSize={10}
          maxSize={50}
          opacity="0.8"
          palette="blue"
          colorGroupingField="value"
          colorGroups={colorGroups}
        >
          <Label enabled={false}></Label>
        </Layer>
        <Tooltip enabled={true}
          customizeTooltip={this.customizeTooltip}
        ></Tooltip>
      </VectorMap>
      </>
      );  
    }
  }

  export default PortCongestionMap;