import React from 'react';
import Chart, {AdaptiveLayout,SeriesTemplate, CommonSeriesSettings, Size, Series, ValueAxis, ArgumentAxis, Tooltip,  Legend,  Label, Marker} from 'devextreme-react/chart';

function customizeTooltip(pointInfo){
    
    if(pointInfo){
        return{
            text: `${pointInfo.seriesName}: ${formatNumber(pointInfo.valueText)}` 
        };
    } else {
        return{
            text: ``
        };
    }   
}

const formatNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits:0
}).format;

export default function PortCongestionChart(gridData){
    var data = gridData.data.chartData;
    var custWidth = Math.round((gridData.data.chartData[0].aTeu + gridData.data.chartData[0].pTeu) / 3000);
/*    if(data){
        data = data.map((rec) => {
            rec.dummy = '';
            return rec;
        });
    } */
    return(
        <div className="chart-cell" >
            <Chart
                dataSource={data}  rotated={true} 
                >
                <CommonSeriesSettings type="stackedBar" argumentField="portGroup"/>
                <Series
                    valueField="aTeu"
                    name="TEU at Anchor"
                    color="#BE1E2D"
                />
                <Series
                    valueField="pTeu"
                    name="TEU at Port"
                />                
                
                <ArgumentAxis visible={false}>
                    <Label visible={false} />

                </ArgumentAxis>
                <ValueAxis visible={false} grid={false} tick={false} endOnTick={false} placeholderSize={0}>
                    <Label visible={false} />
                    
                    
                </ValueAxis>
                <Legend visible={false} />
                
                <Size height={15} width={custWidth}/>
                
                <Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip}/>
            </Chart>
        </div>
    )
}


