import { getFirebaseIdToken } from "../../utils/auth";

export const oDataStoreDefaultSettingWithToken = (fb) => {
  var ret = oDataStoreDefaultSetting;
  ret.onAjaxError = ({ xhr, error}) =>
  {
    if(xhr.status === 401) {
      console.log('Try to refresh token...');
      getFirebaseIdToken(fb, ()=>{
        this.datastore.load();
      }
      );
    }
  }
  return ret;
}

export const oDataStoreDefaultSetting = {
    version: 4,
    beforeSend: (e) => {  
      e.headers = {
          'Authorization': `Bearer ${window.localStorage.getItem("userIdToken")}`
      };
      if (e.params.$filter && e.params.$filter) {
        e.params.$filter = e.params.$filter.replace(/&/g,'@1@');
        e.params.$filter = e.params.$filter.replace(/#/g,'@2@');
        e.params.$filter = e.params.$filter.replace(/\//g,'@3@');
        e.params.$filter = e.params.$filter.replace(/\\/g,'@4@');
        e.params.$filter = e.params.$filter.replace(/"/g,'@5@');
        e.params.$filter = e.params.$filter.replace(/\[/g,'@6@');
        e.params.$filter = e.params.$filter.replace(/]/g,'@7@');
        e.params.$filter = e.params.$filter.replace(/{/g,'@10@');
        e.params.$filter = e.params.$filter.replace(/}/g,'@11@');        
      }
    }
  }

